/**
 *
 * 100vh fix
 * Rafa Cobiella
 * 2020 01 15
 *
 */

const mobileHeight = () => {
	const TOUCH = "ontouchstart" in window;
	const setDocHeight = () => {
		document.documentElement.style.setProperty(
			"--vh",
			`${window.innerHeight / 100}px`
		);
	};

	if (!TOUCH) {
		window.addEventListener("resize", function() {
			setDocHeight();
		});
	}
	window.addEventListener("orientationchange", function() {
		setDocHeight();
	});
	window.addEventListener("DOMContentLoaded", () => {
		setDocHeight();
	});
};

export default mobileHeight;
